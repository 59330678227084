<template>
  <div class="card card-custom gutter-b">
    <div class="col-md-12">
      <b-form @submit="onSubmit">
        <div class="card-header">
          <h4 class="title-card-custom">Entri Penanganan Kasus</h4>
        </div>
        <div class="card-body">
          <b-form-group
            id="input-group-1"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="No. Perkara"
            label-for="perkara"
          >
            <b-form-input
              id="perkara"
              v-model="form.perkara"
              type="text"
              placeholder="No. Perkara"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Kasus Hukum"
            label-for="kasus_hukum"
          >
            <b-form-input
              id="kasus_hukum"
              type="text"
              v-model="form.kasus_hukum"
              placeholder="Kasus Hukum"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Materi/Gugatan/Objek"
            label-for="materi"
          >
            <b-form-input
              id="materi"
              v-model="form.materi"
              placeholder="Materi/Gugatan/Objek"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="penggugat-group-3"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Penggugat"
            label-for="penggugat"
          >
            <b-form-input
              id="penggugat"
              v-model="form.penggugat"
              placeholder="Penggugat"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="tergugat-group-3"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Tergugat"
            label-for="tergugat"
          >
            <b-form-input
              id="tergugat"
              v-model="form.tergugat"
              placeholder="Tergugat"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Direktorat"
            label-for="input-4"
          >
            <b-form-select
              v-model="form.directorate"
              :options="directorates"
              class="mb-3"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>

            <b-form-input
              id="input-4"
              v-model="form.add_note"
              required
              placeholder="Masukkan nama Direktorat"
              v-if="form.directorate === 'G'"
              v-show="form.directorate"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-5"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Laporan/ Progres"
            label-for="laporan"
          >
            <b-form-textarea
              id="laporan"
              v-model="form.laporan"
              placeholder="Laporan/ Progres"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Tanggal Progres"
            label-for="tanggal"
          >
            <b-form-datepicker
              id="tanggal"
              v-model="form.tanggal"
              class="mb-2"
              placeholder="Pilih tanggal progres"
              locale="id"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            id="input-group-7"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Upload Produk"
            label-for="input-7"
            description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
          >
            <b-form-file
              ref="files"
              placeholder="Pilih File"
              drop-placeholder="Drop file di sini..."
              accept=".pdf, .doc, .docx"
              v-model="form.path"
              browse-text="Cari"
              multiple
              :file-name-formatter="formatNames"
              @change="
                validateFileTypeGeneral($event);
                fileSizeChecker('10', $event)"
            ></b-form-file>
          </b-form-group>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-3">
                <button
                  class="ml-2 btn btn-secondary"
                  style="border-radius: 3px;"
                  type="reset"
                  @click="$router.back()"
                >
                  Batal
                </button>
                <button class="ml-2 btn btn-submit" type="submit">Kirim</button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { handleErrors } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import moment from "moment";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let token = "";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [fileSizeChecker, validateFileTypeGeneral],
  name: "TambahAdvokasi",
  data() {
    return {
      new_id: null,
      form: {
        perkara: "",
        kasus_hukum: "",
        materi: "",
        penggugat: "",
        tergugat: "",
        add_note: "",
        laporan: "",
        tanggal: "",
        path: null,
        directorate: null
      },
      directorates: [],
      show: true
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // alert(JSON.stringify(this.form));
      let data = {
        direktorat_id: this.form.directorate,
        kasus_hukum: this.form.kasus_hukum,
        materi: this.form.materi,
        perkara: this.form.perkara,
        penggugat: this.form.penggugat,
        tergugat: this.form.tergugat,
        tanggal: this.form.tanggal ? moment.utc(this.form.tanggal, "YYYY-MM-DD").format() : null
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/advokasi`), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            },
            body: JSON.stringify(data)
          })
            .then(handleErrors)
            .then(response => response.json())
            .then(async data => {
              this.new_id = data.data.id;
              if (this.form.path !== null) {
                let dataUpload = new FormData();
                for (const file of this.form.path) {
                  dataUpload.append("files", file, file.name);
                }
                await fetch(
                  encodeURI(
                    process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`
                  ),
                  {
                    method: "POST",
                    headers: {
                      Authorization: "bearer " + token
                    },
                    body: dataUpload
                  }
                )
                  .then(handleErrors)
                  .then(response => response.json())
                  .then(async dataHasilUpload => {
                    let advocacyProgress = {
                      advokasi_id: data.data.id,
                      laporan: this.form.laporan,
                      path: dataHasilUpload.data.join(";"),
                      tanggal: moment
                        .utc(this.form.tanggal, "YYYY-MM-DD")
                        .format()
                    };
                    await fetch(
                      encodeURI(
                        process.env.VUE_APP_URL_LOCAL + `/advokasi-progress`
                      ),
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "bearer " + token
                        },
                        body: JSON.stringify(advocacyProgress)
                      }
                    )
                      .then(handleErrors)
                      .then(response => response.json())
                      .then(dataHasilProgress => {
                        Swal.fire({
                          position: "center",
                          icon: dataHasilProgress.status,
                          title: "Data berhasil ditambahkan",
                          showConfirmButton: false,
                          timer: 1500
                        });
                        this.onReset();
                        window.history.go(-1);
                      });
                  });
              } else {
                let advokasiProgress = {
                  advokasi_id: this.new_id,
                  laporan: this.form.laporan,
                  tanggal: this.form.tanggal ? moment.utc(this.form.tanggal, "YYYY-MM-DD").format() : null
                };
                await fetch(
                  encodeURI(
                    process.env.VUE_APP_URL_LOCAL + `/advokasi-progress`
                  ),
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "bearer " + token
                    },
                    body: JSON.stringify(advokasiProgress)
                  }
                )
                  .then(handleErrors)
                  .then(response => response.json())
                  .then(dataHasilProgress => {
                    Swal.fire({
                      position: "center",
                      icon: dataHasilProgress.status,
                      title: "Data berhasil ditambahkan",
                      showConfirmButton: false,
                      timer: 1500
                    });
                    this.onReset();
                    window.history.go(-1);
                  });
              }
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal menambahkan data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onReset() {
      // Reset our form values
      this.form.case = "";
      this.form.lawcase = "";
      this.form.claim = "";
      this.form.others = "";
      this.form.progress = "";
      this.form.date = "";
      this.form.file = null;
      this.form.directorate = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      /*this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });*/
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    }
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    });
    fetch(
      encodeURI(process.env.VUE_APP_URL_LOCAL + `/master/direktorat?take=100`),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token
        }
      }
    )
      .then(handleErrors)
      .then(response => response.json())
      .then(items => {
        const detailMenu = items.data;
        let filtered = detailMenu.filter(member => member.name !== "N/A");
        let selectedOptions;
        selectedOptions = [];

        if (filtered) {
          filtered.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
        } 

        this.directorates = selectedOptions;
      });
  }
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
